@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.poppins-bold {
  padding-top: 10px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 700 !important;
  font-style: normal !important;
}
