.App {
  background-color: #CCEED6;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
.NavigationBar {
  background-color: #6FBE47;
}
.socialLogos {
  display: flex;
}
.socialLogo {
  padding: 5px;
}
.mintContainer {
  margin-top: 50px;
}
.preview {
  border: 10px solid black;
  border-radius: 50px;
}
.mintButton {
  background-color: #2A7604 !important;
  border: 0px !important;
  color: white;
  font-size: 20px !important;
}
.oneOfone {
  color: gold;
}
.numMinted {
  color: black;
  padding: 10px;
}
.introduction {
  background-color: #6FBE47;
  border: 5px solid black;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 30px;
  color: white;
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #6FBE47;
  color: white;
  text-align: center;
}
.mobileView {
  text-align: center;
  margin: 60px;
}
.mintAmountButton {
  margin-left: 10px;
  margin-right: 10px;
}